/**
 * @file standalone/basic_image.scss
 *
 * shared styles for basic image
 *
 */

@import '../theme-bootstrap';

.basic-image {
  margin: 0 auto;
  max-width: 1280px;
  &.basic-image--full-width {
    max-width: 100%;
  }
  &__header {
    margin: 0 0 20px 0;
    padding: 0 20px;
    @include breakpoint($landscape-up) {
      margin-bottom: 25px;
    }
  }
  &__headline {
    @include h1;
    margin: 0 0 7px 0;
    @include breakpoint($landscape-up) {
      margin-bottom: 15px;
    }
    > p {
      margin: 0;
      line-height: inherit;
    }
  }
}

// class added in cms
.homepage-wrapper {
  .basic-image {
    @include content-contain--narrow;
  }
}